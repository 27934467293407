header {
    width: 100%;
    height: 190px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: height 0.2s;
}

.smaller {
    height: 30px;
    font-size: 18px;
}

.bigHeader {
    margin-top: 50px;
}

.bigHeader > p {
    font-size: 30px;
    margin-top: 0;
}

@media screen and (max-width: 600px) {
    .bigHeader {
        font-size: 24px;
        margin-top: 60px;
    }

    .bigHeader > p {
        font-size: 16px !important;
    }

    header {
        height: 180px;
    }
}

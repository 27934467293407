header {
    text-align: center;
    font-size: 46px;
    font-weight: bold;
    cursor: pointer;
    background-color: #99e2b4;
    font-family: 'opensans-bold';
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

body {
    background: white;
    color: rgb(3, 102, 102);
    font-family: 'opensans-light';
    margin: 0;
}

h1 {
    font-weight: bold;
    font-size: 26px;
}

h2 {
    font-weight: bold;
    font-size: 20px;
}

h3 {
    font-weight: bold;
    font-size: 18px;
}

h4 {
    font-weight: bold;
    font-size: 16px;
}

p {
    font-size: 16px;
}

code {
    color: rgb(3, 102, 102);
}

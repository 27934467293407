a:link,
a:visited,
a:hover,
a:active {
    color: #024a4a;
}

.posts {
    width: 90%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 210px;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.posts > div {
    width: 270px;
    margin: 10px 10px 0 10px;
    padding: 10px 10px 0 10px;
}

.posts > div > a > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.posts > div > a > img {
    max-width: 100%;
}

.postTitle {
    margin-bottom: 0;
    margin-top: 0;
}

.postDescription {
    margin-top: 0;
}

.info {
    margin: 0;
    font-size: 12px;
    display: flex !important;
    align-items: center;
}

.info {
    overflow: visible !important;
}

.info > img {
    display: inline;
    border: none;
    width: 15px;
    margin: 0;
    padding-top: 2px;
    padding-right: 2px;
}

.tag {
    color: white;
    background: rgb(3, 102, 102);
    padding: 2px;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 2px;
    margin-left: 5px;
}

@media screen and (max-width: 600px) {
    .posts > div {
        border-bottom: #024a4a solid 1px;
        padding-left: 0;
        margin-left: 0;
        padding-right: 0;
        margin-right: 0;
        width: 95%;
    }

    .posts > div > a > img {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .posts > div:last-of-type {
        border-bottom: none;
    }
}

.post {
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.post > h1 {
    margin-bottom: 0;
}

.post > img {
    width: 100%;
    max-width: 1000px;
}

li {
    overflow-wrap: break-word;
}

img {
    max-width: 70%;
    height: auto;
    display: block;
    margin: 10px auto 10px auto;
    /* border: rgb(3, 102, 102) solid 2px; */
}

.link:hover {
    font-size: 88px;
    font-size-adjust: 20px;
}

.link > a > h2 {
    margin-bottom: 0;
}

.link > a > p {
    margin-top: 0;
}

.postRecommendation {
    background: #f8f8f8;
    padding-top: 20px;
    padding-bottom: 20px;
}

.postRecommendationContent {
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 600px) {
    .post {
        margin-top: 60px;
    }

    .post,
    .postRecommendationContent {
        width: 95%;
    }

    img {
        max-width: 100%;
    }
}

code {
    color: rgb(3, 102, 102) !important;
    font-weight: 900;
    overflow-wrap: break-word;
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.7em;
    color: black;
    background: #f8f8f8;
    border-radius: 15px;
}

.hljs-comment,
.hljs-quote {
    color: #998;
    font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: black;
    font-weight: 700;
}

.hljs-literal,
.hljs-number,
.hljs-tag .hljs-attr,
.hljs-template-variable,
.hljs-variable {
    color: teal;
}

.hljs-doctag,
.hljs-string {
    color: rgb(3, 102, 102);
}

.hljs-section,
.hljs-selector-id,
.hljs-title {
    color: rgb(3, 102, 102);
    font-weight: 700;
}

.hljs-subst {
    font-weight: 400;
}

.hljs-class .hljs-title,
.hljs-type {
    color: #458;
    font-weight: 700;
}

.hljs-attribute,
.hljs-name,
.hljs-tag {
    color: navy;
    font-weight: 400;
}

.hljs-link,
.hljs-regexp {
    color: #009926;
}

.hljs-bullet,
.hljs-symbol {
    color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
    color: #0086b3;
}

.hljs-meta {
    color: #999;
    font-weight: 700;
}

.hljs-deletion {
    background: #fdd;
}

.hljs-addition {
    background: #dfd;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: 700;
}
